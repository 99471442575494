<template>
	<div v-if="$api.texts['order-online-pending']">
		<Order state="pending">
			<div v-html="$api.texts['order-online-pending'].raw"></div>
			<p>
				Její stav můžete zkontrolovat <a :href="url">zde</a>.
			</p>
		</Order>
	</div>
</template>

<script>
	import Order from "../Order";
	import {EventBus} from "../../../event-bus";

	export default {
		name: "OrderOnlineError",
		metaInfo: {
			title: "Očekáváme Vaši platbu",
			meta: [{
				name: "robots",
				content: "noindex"
			}]
		},
		components: {Order},
		computed: {
			url() {
				return atob(decodeURIComponent(this.$route.params.url).replace(/`/g, '/'));
			}
		},
		mounted() {
			// Hide delivery modal should it be open
			EventBus.$emit("toggleDeliveryModal", false);
		}
	}
</script>